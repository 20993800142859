import { Card, CardContent } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import Markdown from '@components/Markdown';

export default function ContestInformation() {
  const { contest } = useOutletContext();
  return (
    <Card>
      <CardContent sx={{ color: 'text.primary' }}>
        <Markdown children={contest.description} />
      </CardContent>
    </Card>
  );
}
