import useAuth from 'hooks/useAuth';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useToast } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'routes/paths';

const GuestGuard = ({ children }) => {
  const { t } = useTranslation();
  const [{ isAuthenticated }] = useAuth();
  const { showInfo } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      showInfo(t('You have already logged in'));
      navigate(ROUTES.DASHBOARD_ROOT);
    }
  }, []);

  return !isAuthenticated && <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
