import { useDispatch, useSelector } from 'react-redux';
import { actions } from '@redux/reducers/authReducer';
import { useCallback } from 'react';
import { isNil } from 'lodash';

export default function useAuth() {
  const authState = useSelector((state) => state['authReducer']);
  const dispatch = useDispatch();
  const saveCredentials = (data) => dispatch(actions.saveCredentials(data));
  const updateUser = (user) => dispatch(actions.updateUser(user));
  const logOut = () => dispatch(actions.logOut());

  const hasRole = useCallback(
    (requiredRole) => isNil(requiredRole) || authState?.user?.role >= requiredRole,
    [authState.user],
  );

  return [authState, { saveCredentials, updateUser, logOut, hasRole }];
}
