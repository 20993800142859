import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CodeService } from 'services';
import RankingTable from './components/RankingTable';

export default function Ranking() {
  const { contestId = 0 } = useParams();

  const [problems, setProblems] = useState([]);
  const [contest, setContest] = useState({});

  useEffect(() => {
    CodeService.getProblemList({ contestId, fields: 'code,id,firstSolved,multiplier', ranking: true }).then(
      ({ data }) => setProblems(data),
    );
    CodeService.getContestDetail(contestId).then(({ data }) => setContest(data));
  }, []);
  return <RankingTable problems={problems} contest={contest} />;
}
