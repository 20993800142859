import API_CONFIG from 'routes/apiConfig';
import HttpClient from './HttpClient';

export class FileService {
  static uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);
    return HttpClient.post(API_CONFIG.UPLOAD_FILE, formData).then((d) => d['viewUri']);
  }
}

export default FileService;
