import ROUTES from 'routes/paths';
import Loadable from '@components/Loadable';
import { lazy } from 'react';
import DashboardLayout from 'layouts/dashboard';
import AuthGuard from 'guards/AuthGuard';
import { defaultSidebar } from 'layouts/dashboard/SidebarConfig';

const UserProfile = Loadable(lazy(() => import('./UserProfile')));
const UserAccount = Loadable(lazy(() => import('./UserAccount')));

const USER_ROUTES = {
  path: ROUTES.USER_ROOT,
  element: (
    <AuthGuard>
      <DashboardLayout sidebarConfig={defaultSidebar} />
    </AuthGuard>
  ),
  children: [
    {
      path: ROUTES.USER_PROFILE,
      element: <UserProfile />,
    },
    {
      path: ROUTES.USER_SETTINGS,
      element: <UserAccount />,
    },
  ],
};

export default USER_ROUTES;
