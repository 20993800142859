import { Link as RouterLink } from 'react-router-dom';
import { Link as MLink } from '@mui/material';

const Link = (props) => <MLink component={RouterLink} {...props} style={{ textDecoration: 'none' }} />;

export default Link;

Link.defaultProps = {
  underline: 'none',
};
