import APP_CONFIG from '@config';
import { SocketEvent } from 'contants/socketEvent';
import { useAuth, useToast } from 'hooks';
import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { io } from 'socket.io-client';
import { storage } from 'utils';
import { LOCAL_STORAGE_KEYS } from 'utils/constants';

const socket = io(APP_CONFIG.socketUrl);
export const SocketContext = createContext([socket, false, false]);

export const SocketContextProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [{ isAuthenticated }] = useAuth();
  const { showError } = useToast();

  const updateSocketState = () => {
    setConnected((prevState) => (prevState === socket.connected ? prevState : socket.connected));
    setAuthorized(false);
  };

  useEffect(() => {
    if (connected && isAuthenticated) {
      socket.emit(SocketEvent.authorize, {
        token: storage.get(LOCAL_STORAGE_KEYS.accessToken),
      });
    }
  }, [connected, isAuthenticated]);

  useEffect(() => {
    socket.on('connect', updateSocketState);
    socket.on('reconnect', updateSocketState);
    socket.on('disconnect', updateSocketState);
    socket.on(SocketEvent.authorize, setAuthorized);
    socket.on(SocketEvent.error, showError);
  }, []);

  useEffect(() => {
    console.debug(`SOCKET CONNECTED - ${connected} - ${socket.id}`);
  }, [connected]);

  return <SocketContext.Provider value={[socket, connected, authorized]}>{children}</SocketContext.Provider>;
};

SocketContextProvider.propTypes = {
  children: PropTypes.node,
};
