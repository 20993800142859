import {
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Link,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ROUTES from 'routes/paths';
import { MockService as AnnouncementService } from 'services';
import { formatDate } from 'utils';
import truncate from 'utils/truncate';
import { useToast } from 'hooks';
import { Markdown } from '@components';

export default function Announcement() {
  const { contestId = 0 } = useParams();
  const [announcement, setAnnouncement] = useState();
  const [rows, setRows] = useState([]);
  const { showError } = useToast();

  useEffect(async () => {
    try {
      const { data } = await AnnouncementService.getAnnouncementList(contestId);
      setRows(data);
    } catch (e) {
      showError(e.message);
    }
  }, [contestId]);

  return (
    <>
      <Card>
        <CardContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow key={`announcement-${i}`}>
                    <TableCell component="th" scope="row">
                      <Link onClick={() => setAnnouncement(row)} sx={{ ':hover': { cursor: 'pointer' } }}>
                        {truncate(row.title, 100)}
                      </Link>
                    </TableCell>
                    <TableCell align="right">{formatDate(row.createdAt, 'DDMMYYYYhhmm')}</TableCell>
                    <TableCell align="right">
                      <Link
                        component={RouterLink}
                        to={ROUTES.USER_PROFILE.replace(':username', row?.createdBy?.username)}
                      >
                        {row?.createdBy?.fullName ?? row?.createdBy?.username}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
                {rows.length === 0 && (
                  <Typography variant="subtitle2" align="center">
                    No announcement found
                  </Typography>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <Dialog open={announcement} fullWidth maxWidth="md" onClose={() => setAnnouncement(null)}>
        <DialogContent>
          <Typography variant="subtitle1">{announcement?.title}</Typography>
          <Typography variant="subtitle2" color="text.secondary">
            at {formatDate(announcement?.createdAt, 'DDMMYYYYhhmm')}, by{' '}
            {announcement?.createdBy?.fullName ?? announcement?.createdBy?.username}
          </Typography>
          <Markdown>{announcement?.description}</Markdown>
        </DialogContent>
      </Dialog>
    </>
  );
}
