import { useAppState } from 'hooks';
import useSettings from 'hooks/useSettings';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
import { Box, CircularProgress, Container } from '@mui/material';
import APP_CONFIG from '@config';
import { useTranslation } from 'react-i18next';

const Page = forwardRef(({ children, title = '', loading, container, ...other }, ref) => {
  const { t } = useTranslation();
  const { themeStretch } = useSettings();
  const [appState] = useAppState();

  return (
    <Box ref={ref} {...other} position="relative">
      {title && (
        <Helmet>
          <title>{`${t(title)} | ${APP_CONFIG.siteName}`}</title>
        </Helmet>
      )}

      {(loading || appState.loading) && (
        <Box
          position="absolute"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ top: 0, bottom: 0, right: 0, left: 0 }}
        >
          <CircularProgress />
        </Box>
      )}
      {container ? <Container children={children} maxWidth={themeStretch ? false : 'lg'} /> : children}
    </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  loading: PropTypes.bool,
  container: PropTypes.bool,
};

Page.defaultProps = {
  container: true,
};

export default Page;
