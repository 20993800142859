import { useEffect, useMemo, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { MockService as AnnouncementService } from 'services';
import { useParams } from 'react-router-dom';
import { useToast } from 'hooks';

export function ContestBanner({ type, _contestId, isInProblemDetail }) {
  const { contestId = _contestId ?? 0 } = useParams();
  const { showError } = useToast();
  const [items, setItems] = useState([]);

  const bannerTypes = {
    square: {
      height: 300,
      getDataFunc: AnnouncementService.getSquareBanner,
    },
    horizontal: {
      height: 200,
      getDataFunc: AnnouncementService.getBanner,
    },
  };

  const b = useMemo(() => bannerTypes[type], [type]);

  useEffect(async () => {
    try {
      if (b) {
        const { data } = await b.getDataFunc(contestId);
        if (parseInt(contestId, 10) >= 10028 && parseInt(contestId, 10) <= 10035 && isInProblemDetail) {
          setItems([
            {
              name: 'Zenlish',
              image: 'https://i.ibb.co/S6D9B1n/HD.jpg',
            },
          ]);
        } else {
          setItems(data);
        }
      }
    } catch (e) {
      showError(e.message);
    }
  }, [contestId]);

  return (
    Boolean(b) &&
    items.length > 0 && (
      <Carousel
        fullHeightHover
        sx={{ width: '100%', borderRadius: 3, marginBottom: 2 }}
        duration={1000}
        height={b.height}
        indicators={false}
        className="banner-carousel"
      >
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
    )
  );
}

const Item = ({ item }) => (
  <img src={item.image} alt={item.name} style={{ height: '100%', objectFit: 'cover', margin: '0 auto' }} />
);
