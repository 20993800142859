export const HERO_DATA = [
  {
    imageUrl: '/static/home/training/img7.jpg',
    title: 'Website quản lý thành viên',
    color: 'Black',
    description:
      'Team Web CLB cùng nhau xây dựng website quản lý thành viên. Website chứa các thông tin về thành viên, lịch sử hoạt động và thông tin của các sự kiện',
  },
  {
    imageUrl: '/static/home/training/img6.jpg',
    title: 'Game Team Jam IT PTIT',
    color: 'white',
    description:
      'Game Team Jam IT PTIT là cuộc thi tổ chức bởi team game nhằm mục đích tạo sân chơi giải trí lành mạnh, giúp các thành viên thỏa sức tưởng tượng, sáng tạo vầ thể hiện bản thân. Đồng thời còn tạo ra cơ hội giúp mọi người trao đổi kiến thức, trau dồi kỹ năng lập trình game.',
  },
  {
    imageUrl: '/static/home/training/img9.jpg',
    title: 'Final Contest',
    color: 'white',
    description:
      'Final Contest là bài kiểm tra cuối cùng của CLB dành cho các tân sinh viên. Bài kiểm tra mang tính chất quan trọng vì nó sẽ quyết định rất nhiều tới kết quả đánh giá của kỳ training',
  },
];

export const TRAINING_STEPS = [
  {
    title: 'Lập trình căn bản',
    description: (
      <>
        Chương trình học "Lập trình căn bản" của CLB IT PTIT mở ra một cơ hội quý báu cho những người mới khám phá thế
        giới lập trình và muốn tìm hiểu sâu hơn về ngôn ngữ lập trình. Tại đây, bạn sẽ trải qua một hành trình đào tạo
        quan trọng. Các anh chị khóa trước sẽ hướng dẫn bạn tư duy lập trình và sử dụng thành thạo ngôn ngữ C/C++. Quá
        trình này gồm hai giai đoạn: Giai đoạn đầu tiên tập trung vào lập trình C từ đầu học kỳ I của năm nhất và giai
        đoạn thứ hai tiếp tục với lập trình C++ từ đầu học kỳ II. Quá trình này sẽ giúp bạn xây dựng nền tảng vững chắc
        trong lĩnh vực lập trình
        <br />
        <br />
        Bên cạnh việc học qua các bài giảng được biên soạn và trình bày bởi các thành viên của ban Cơ Bản, bạn còn được
        tham gia vào quá trình thực hành thông qua hệ thống luyện tập lập trình mà CLB cung cấp.
      </>
    ),
    imageList: ['/static/home/training/img1.jpg', '/static/home/training/img2.jpg', '/static/home/training/img3.jpg'],
  },
  {
    title: 'Cấu trúc dữ liệu và giải thuật',
    description: (
      <>
        Lớp "Cấu trúc dữ liệu và Giải thuật" tại CLB IT PTIT sẽ chính thức khởi động từ giữa học kỳ II của năm thứ nhất,
        được tổ chức và giảng dạy bởi ban Thuật Toán. Đây là cơ hội quý báu cho các thành viên có mong muốn nắm vững về
        tổ chức dữ liệu và các giải thuật phức tạp trong thế giới lập trình. Ban Thuật Toán sẽ đảm nhiệm việc truyền đạt
        kiến thức sâu rộng về các cấu trúc dữ liệu và cách áp dụng chúng để giải những bài toán khó, hướng tới thi đấu
        lập trình.
      </>
    ),
    imageList: ['/static/home/training/img4.jpg', '/static/home/training/img5.jpg'],
  },
  {
    title: 'Lập trình hướng đối tượng',
    description: (
      <>
        Lớp "Lập trình Hướng đối tượng" tại CLB IT PTIT là một chương trình học chất lượng, được tổ chức tại kỳ I của
        năm hai nhằm giúp thành viên hiểu rõ về lập trình hướng đối tượng. Từ đó bạn có thể xây dựng các ứng dụng đầu
        tiên của riêng mình.
        <br />
        <br />
        Khóa học này là một phần không thể thiếu trong việc xây dựng và mở rộng nền tảng trong lĩnh vực lập trình.
      </>
    ),
    imageList: [],
  },
  {
    title: 'Tham gia vào team dự án',
    description: (
      <>
        Sau khi đã được trang bị những kiến thức cần thiết, tại đầu kỳ II năm hai các thành viên sẽ được lựa chọn nhóm
        dự án để tham gia. Có 4 nhóm dự án của CLB bao gồm: Team Web, Team Mobile, Team AI và Team Game.
        <br />
        <br />
        Mỗi nhóm dự án không chỉ là môi trường đào tạo, hướng dẫn cho bạn về kiến thức nền tảng của từng lĩnh vực mà còn
        được trực tiếp thực hành thông qua việc tham gia vào các dự án. Việc này giúp bạn trang bị những hiểu biết cơ
        bản về công việc và hình thành chiều sâu trong việc lựa chọn hướng phát triển nghề nghiệp trong tương lai.
      </>
    ),
    imageList: ['/static/home/training/img6.jpg', '/static/home/training/img7.jpg', '/static/home/training/img8.jpg'],
  },
  {
    title: 'Thực tập doanh nghiệp',
    description: (
      <>
        IT PTIT là câu lạc bộ hiếm hoi có mối liên kết mạnh mẽ với các doanh nghiệp CNTT. Kỳ hè là thời điểm CLB hợp tác
        cùng với doanh nghiệp để tạo điều kiện cho các thành viên đi thực tập sớm, thúc đẩy cơ hội nghề nghiệp.
        <br />
        <br />
        Một số lợi ích của việc thực tập sớm mà các thành viên có thể nhận được: có thêm nhiều kinh nghiệm làm việc, học
        hỏi từ chuyên gia, hiểu rõ hơn về ngành và có thêm thu nhập.
      </>
    ),
    imageList: [],
  },
];
