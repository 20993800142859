import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import ROUTES from 'routes/paths';
import { GuestGuard } from 'guards';
import { Navigate } from 'react-router-dom';
import Loadable from '@components/Loadable';
import { lazy } from 'react';

// Dashboard
const Login = Loadable(lazy(() => import('./Login')));
const Register = Loadable(lazy(() => import('./Register')));
const Verify = Loadable(lazy(() => import('./Verify')));
const ResetPassword = Loadable(lazy(() => import('./ResetPassword')));

const AUTH_ROUTES = {
  path: ROUTES.AUTH_ROOT,
  element: (
    <GuestGuard>
      <LogoOnlyLayout />
    </GuestGuard>
  ),
  children: [
    {
      path: ROUTES.AUTH_ROOT,
      element: <Navigate to={ROUTES.LOGIN} replace />,
    },
    {
      path: ROUTES.LOGIN,
      element: <Login />,
    },
    {
      path: ROUTES.REGISTER,
      element: <Register />,
    },
    {
      path: ROUTES.VERIFY_ACCOUNT,
      element: <Verify />,
    },
    {
      path: ROUTES.RESET_PASSWORD,
      element: <ResetPassword />,
    },
  ],
};

export default AUTH_ROUTES;
