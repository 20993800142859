import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * @param {string} key Search key
 * @returns string
 */
const useQuery = (key) => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search).get(key), [search]);
};

export default useQuery;
