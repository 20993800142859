import { SocketContextProvider } from 'contexts/SocketContext';
import Announcement from 'pages/Dashboard/components/Announcement';
import ContestInformation from 'pages/Dashboard/contest/components/ContestInformation';
import ProblemList from 'pages/Dashboard/problem/components/ProblemList';
import Ranking from 'pages/Dashboard/ranking/Ranking';
import SubmissionList from 'pages/Dashboard/submission/components/SubmissionList';
import { Navigate } from 'react-router-dom';
import ROUTES from 'routes/paths';
import { AuthGuard } from 'guards';
import Loadable from '@components/Loadable';
import { lazy } from 'react';
import DashboardLayout from 'layouts/dashboard';
import { defaultSidebar } from 'layouts/dashboard/SidebarConfig';
import { CodeService } from 'services';

const GeneralApp = Loadable(lazy(() => import('./GeneralApp')));
const Problem = Loadable(lazy(() => import('./problem/Problem')));
const ProblemDetail = Loadable(lazy(() => import('./problem/ProblemDetail')));
const Contest = Loadable(lazy(() => import('./contest/Contest')));
const ContestDetail = Loadable(lazy(() => import('./contest/ContestDetail')));
const Submission = Loadable(lazy(() => import('./submission/Submission')));
// const GlobalRanking = Loadable(lazy(() => import('./ranking/GlobalRanking')));

const DASHBOARD_ROUTES = {
  path: ROUTES.DASHBOARD_ROOT,
  element: (
    <AuthGuard>
      <SocketContextProvider>
        <DashboardLayout sidebarConfig={defaultSidebar} />
      </SocketContextProvider>
    </AuthGuard>
  ),
  children: [
    {
      path: ROUTES.DASHBOARD_ROOT,
      element: <GeneralApp />,
    },
    {
      path: ROUTES.PROBLEM_LIST,
      element: <Problem />,
    },
    {
      path: ROUTES.PROBLEM_DETAIL,
      element: <ProblemDetail />,
    },
    {
      path: ROUTES.CONTEST_LIST,
      element: <Contest getDataFunc={CodeService.getContestList} />,
    },
    {
      path: ROUTES.CONTEST_PROBLEM_LIST.replace(':contestId', 0),
      element: <Navigate to={ROUTES.PROBLEM_LIST} replace />,
    },
    {
      path: ROUTES.CONTEST_SUBMISSION_LIST.replace(':contestId', 0),
      element: <Navigate to={ROUTES.SUBMISSION_LIST} replace />,
    },
    {
      path: ROUTES.CONTEST_DETAIL.replace(':contestId', 0),
      element: <Navigate to={ROUTES.PROBLEM_LIST} replace />,
    },
    {
      path: ROUTES.CONTEST_DETAIL,
      element: <ContestDetail />,
      children: [
        {
          path: ROUTES.CONTEST_DETAIL,
          element: <ContestInformation />,
        },
        {
          path: ROUTES.CONTEST_LEADERBOARD,
          element: <Ranking />,
        },
        {
          path: ROUTES.CONTEST_PROBLEM_LIST,
          element: <ProblemList />,
        },
        {
          path: ROUTES.CONTEST_SUBMISSION_LIST,
          element: <SubmissionList />,
        },
        {
          path: ROUTES.CONTEST_ANNOUNCEMENT_LIST,
          element: <Announcement />,
        },
      ],
    },
    {
      path: ROUTES.SUBMISSION_LIST,
      element: <Submission />,
    },
  ],
};

export default DASHBOARD_ROUTES;
