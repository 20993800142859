import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'routes/paths';
import { useToast } from 'hooks';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { USER_ROLES } from 'utils/constants';

const AuthGuard = ({ children, role }) => {
  const { t } = useTranslation();
  const [{ isAuthenticated, user }] = useAuth();
  const { showError } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      showError(t('Please login to continue'));
      navigate(ROUTES.LOGIN, { replace: true });
    }
    if (user?.role < role) {
      showError(t('You do not have permission to access this page.'));
      navigate(ROUTES.FORBIDDEN, { replace: true });
    }
  }, [isAuthenticated]);

  return isAuthenticated && <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  role: PropTypes.number,
};

AuthGuard.defaultProps = {
  role: USER_ROLES.User,
};

export default AuthGuard;
