import { storage } from 'utils';
import { LOCAL_STORAGE_KEYS } from 'utils/constants';

const initialState = {
  user: null,
  isAuthenticated: false,
};

const actionTypes = {
  SAVE_CREDENTIALS: 'SAVE_CREDENTIALS',
  UPDATE_USER: 'UPDATE_USER',
  LOG_OUT: 'LOG_OUT',
};

const authReducer = (state = initialState, action = {}) => {
  const { type, payload = {} } = action;
  switch (type) {
    case actionTypes.SAVE_CREDENTIALS:
      storage.set(LOCAL_STORAGE_KEYS.accessToken, payload.accessToken);
      storage.set(LOCAL_STORAGE_KEYS.refreshToken, payload.refreshToken);
      return {
        ...state,
        user: payload.user,
        isAuthenticated: true,
      };
    case actionTypes.UPDATE_USER:
      return {
        ...state,
        user: payload,
      };
    case actionTypes.LOG_OUT:
      storage.remove(LOCAL_STORAGE_KEYS.accessToken, payload.accessToken);
      storage.remove(LOCAL_STORAGE_KEYS.refreshToken, payload.refreshToken);
      return { ...initialState };
    default:
      return state;
  }
};

const saveCredentials = (payload) => ({
  type: actionTypes.SAVE_CREDENTIALS,
  payload,
});

const updateUser = (payload) => ({ type: actionTypes.UPDATE_USER, payload });

const logOut = () => ({ type: actionTypes.LOG_OUT });

export default authReducer;
export const actions = { saveCredentials, logOut, updateUser };
