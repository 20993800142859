import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, FacebookAuthProvider, GoogleAuthProvider, GithubAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCI2WLGizU8gy_WNIQR5MKZRDIIaJA2QuM',
  authDomain: 'itptit0.firebaseapp.com',
  projectId: 'itptit0',
  storageBucket: 'itptit0.appspot.com',
  messagingSenderId: '849629456063',
  appId: '1:849629456063:web:7af604bee407c5fcd3880a',
  measurementId: 'G-RDB0MKLLZL',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const facebookAuthProvider = new FacebookAuthProvider();
const googleAuthProvider = new GoogleAuthProvider();
const githubAuthProvider = new GithubAuthProvider();

export default class FirebaseService {
  static loginWithFacebook() {
    return signInWithPopup(auth, facebookAuthProvider);
  }

  static loginWithGoogle() {
    return signInWithPopup(auth, googleAuthProvider);
  }

  static loginWithGithub() {
    return signInWithPopup(auth, githubAuthProvider);
  }
}
