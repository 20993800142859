import parseFromJson from './parseFromJson';

export default {
  get: (key) => parseFromJson(localStorage.getItem(key)),
  set: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  remove: (key) => {
    localStorage.removeItem(key);
  },
};
