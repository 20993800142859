import { Icon } from '@mui/material';
import ROUTES from 'routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Trang chủ',
    path: ROUTES.HOME,
    icon: <Icon children="home" {...ICON_SIZE} />,
  },
  {
    title: 'Đào tạo',
    path: ROUTES.TRAINING,
    icon: <Icon children="school" {...ICON_SIZE} />,
  },
  {
    title: 'Hoạt động',
    path: ROUTES.ACTIVITIES,
    icon: <Icon children="event" {...ICON_SIZE} />,
  },
  {
    title: 'Thành viên',
    path: ROUTES.MEMBER,
    icon: <Icon children="people" {...ICON_SIZE} />,
  },
];

export default menuConfig;
