const API_CONFIG = Object.freeze({
  /**
   * File services
   */
  UPLOAD_FILE: 'https://file.itptit.com/files',

  /* Auth */
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  SOCIAL_LOGIN: '/auth/social-login',
  REFRESH_TOKEN: '/auth/refresh-token',
  VERIFY_ACCOUNT: '/auth/account-verify',
  SEND_VERIFY_OTP: '/auth/account-verify/otp',
  RESET_PASSWORD: '/auth/password-recover',
  RESET_PASSWORD_OTP: '/auth/password-recover/otp',
  /* User */
  PUBLIC_PROFILE: '/users/:username',
  UPDATE_PROFILE: '/users/me',
  UPDATE_AVATAR: '/users/me/avatar',
  CHANGE_PASSWORD: '/users/me/password',
  /* Code */
  ADMIN_PROBLEM_LIST: '/admin/problems',
  ADMIN_PROBLEM_DETAIL: '/admin/problems/:problemId',

  PROBLEM_LIST: '/problems',
  PROBLEM_DETAIL: '/problems/:problemId',
  PROBLEM_CODE: '/problems/code/:contestId',
  CREATE_PROBLEM: '/admin/problems',
  UPDATE_PROBLEM: '/admin/problems/:problemId',
  REMOVE_PROBLEM: '/admin/problems/:problemId',
  DUPLICATE_PROBLEM: '/admin/problems/:problemId/duplicateTo/:contestId',

  /* Contest */
  ADMIN_CONTEST_LIST: '/admin/contests',
  ADMIN_CONTEST_DETAIL: '/admin/contests/:contestId',
  CREATE_CONTEST: '/admin/contests',
  UPDATE_CONTEST: '/admin/contests/:contestId',
  REMOVE_CONTEST: '/admin/contests/:contestId',

  JOIN_CONTEST: '/contests/:contestId/join',
  GET_CONTEST_JOINED: '/contests/joined',
  CONTESTANT_LIST: '/contests/:contestId/contestants',
  CONTEST_LIST: '/contests',
  CONTEST_DETAIL: '/contests/:contestId',
  CONTEST_RANKING: '/ranking/:contestId',
  /* Submission */
  SUBMISSION_LIST: '/submissions',
  CREATE_SUBMISSION: '/submissions',
  CREATE_TEST_SUBMISSION: '/admin/submissions',
  SUBMISSION_DETAIL: '/submissions/:submissionId',
  ADMIN_SUBMISSION_DETAIL: '/admin/submissions/:submissionId',
  /* Ranking */
  GET_LEADERBOARD: '/leaderboard/:contestId',
  GET_REAL_LEADERBOARD: '/leaderboard/:contestId/real',

  /* Admin module */
  GET_USER_LIST: '/admin/users',
  GET_USER: '/admin/users/:id',
  UPDATE_USER: '/admin/users/:id',

  /* Recruit members */
  MEMBER_REGISTRATION: '/member-registration',
  GET_MEMBER_STATUS: '/member',
  ADMIN_GET_MEMBER_LIST: '/export',
});

export default API_CONFIG;
