import API_CONFIG from 'routes/apiConfig';
import { storage } from 'utils';
import { LOCAL_STORAGE_KEYS } from 'utils/constants';
import HttpClient from './HttpClient';

class AuthService {
  static login({ username, password }) {
    return HttpClient.post(API_CONFIG.LOGIN, {
      identifier: username,
      password,
    });
  }

  static register(payload) {
    return HttpClient.post(API_CONFIG.REGISTER, payload);
  }

  static socialLogin(token) {
    return HttpClient.post(API_CONFIG.SOCIAL_LOGIN, { token });
  }

  static refreshToken() {
    return HttpClient.post(API_CONFIG.REFRESH_TOKEN, {
      refreshToken: storage.get(LOCAL_STORAGE_KEYS.refreshToken),
    });
  }

  static verifyAccount(payload) {
    return HttpClient.post(API_CONFIG.VERIFY_ACCOUNT, payload);
  }

  static sendVerifyOTP(payload) {
    return HttpClient.post(API_CONFIG.SEND_VERIFY_OTP, payload);
  }

  static sendResetPasswordOTP(payload) {
    return HttpClient.post(API_CONFIG.RESET_PASSWORD_OTP, payload);
  }

  static resetPassword(payload) {
    return HttpClient.post(API_CONFIG.RESET_PASSWORD, payload);
  }
}

export default AuthService;
