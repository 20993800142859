import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import logoSingle from 'assets/images/logo-single-new.png';

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <img src={logoSingle} width="100%" height="100%" alt="Logo" />
    </Box>
  );
}
