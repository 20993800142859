import { Page } from '@components';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, Tooltip } from '@mui/material';
import { useAppState, useToast } from 'hooks';
import useAuth from 'hooks/useAuth';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { CodeService } from 'services';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/clike/clike';
import 'codemirror/mode/python/python';
import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/scroll/simplescrollbars.css';
import { PROGRAM_LANGUAGE } from 'utils/constants';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { Input, Output } from '@mui/icons-material';
import HttpClient from 'services/HttpClient';

export const SubmissionViewDialog = forwardRef((props, ref) => {
  const [isOpen, setOpen] = useState(false);
  const { showError } = useToast();
  const [submission, setSubmission] = useState(null);
  const [, { hideLoading, showLoading }] = useAppState();
  const [{ user }] = useAuth();

  const view = async (submissionId) => {
    setSubmission(false);
    setOpen(true);
    showLoading();

    try {
      if (user.role > 0) {
        const { data: submissionData } = await CodeService.getAdminSubmissionDetail(submissionId);
        const { data: problem } = await CodeService.adminGetProblemDetail(submissionData.problem.id);
        setSubmission({
          ...submissionData,
          testResults: submissionData.testResults.map((testResult) => {
            const test = problem.tests.find((test) => test.uuid === testResult.testUuid);
            return { ...testResult, inputUri: test.inputUri, outputUri: test.outputUri };
          }),
        });
      } else {
        const response = await CodeService.getSubmissionDetail(submissionId);
        setSubmission(response.data);
      }
    } catch (e) {
      showError(e.message);
      setOpen(false);
    }
    hideLoading();
  };

  useImperativeHandle(ref, () => ({
    view,
  }));

  const editorOptions = useMemo(
    () => ({
      mode: PROGRAM_LANGUAGE?.[submission?.language]?.type,
      theme: 'material',
      lineNumbers: true,
      simplescrollbars: true,
      scrollbarStyle: 'simple',
    }),
    [submission],
  );

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth onClose={() => setOpen(false)}>
      <Page container={false}>
        <DialogTitle>#{submission?.id}</DialogTitle>
        <DialogContent>
          <CodeMirror options={editorOptions} value={submission?.source} onBeforeChange={() => {}} />

          {user.role > 0 && (
            <Box my={1}>
              <DataGridPremium
                columns={COLUMNS}
                rows={submission?.testResults || []}
                getRowId={(row) => row.testUuid}
                disableRowSelectionOnClick
                disableColumnMenu
              />
            </Box>
          )}
        </DialogContent>
      </Page>
    </Dialog>
  );
});

const COLUMNS = [
  {
    headerName: 'Test uuid',
    field: 'testUuid',
    flex: 2,
    sortable: false,
  },
  {
    headerName: 'Time',
    field: 'time',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    headerName: 'Memory',
    field: 'memory',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    headerName: 'Result',
    field: 'result',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    headerName: 'View',
    field: 'action',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    renderCell: ({ row }) => <ViewActions row={row} />,
  },
];

const ViewActions = ({ row }) => {
  const [isOpenView, setIsOpenView] = useState(false);
  const [content, setContent] = useState('');
  const { showError } = useToast();

  const handleOpenInputView = async () => {
    setIsOpenView(true);
    try {
      const data = await HttpClient.get(row.inputUri);
      setContent(data);
    } catch (e) {
      showError(e.message);
    }
  };

  const handleOpenOutputView = async () => {
    setIsOpenView(true);
    try {
      const data = await HttpClient.get(row.outputUri);
      setContent(data);
    } catch (e) {
      showError(e.message);
    }
  };

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title="Input" placement="left">
        <IconButton color="primary" onClick={handleOpenInputView}>
          <Input />
        </IconButton>
      </Tooltip>

      <Tooltip title="Output" placement="right">
        <IconButton color="info" onClick={handleOpenOutputView}>
          <Output />
        </IconButton>
      </Tooltip>

      <Dialog open={isOpenView} maxWidth="md" fullWidth onClose={() => setIsOpenView(false)}>
        <DialogContent>
          <TextField value={content} multiline fullWidth rows={5} />
        </DialogContent>
      </Dialog>
    </Stack>
  );
};
