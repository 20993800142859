import { useState } from 'react';
import { useToast } from 'hooks';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

const useConfirmationDialog = (submitFunc, _message = 'Are you sure to do this action', _title = 'Confirm') => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const { showSuccess, showError } = useToast();
  const { t } = useTranslation();
  const [payload, setPayload] = useState(null);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const data = await submitFunc(payload);
      if (data?.message) showSuccess(data?.message);
      toggle();
    } catch (e) {
      showError(e.message);
    }
    setLoading(false);
    if (typeof payload.callback === 'function') {
      payload.callback();
    }
  };
  const toggle = (payload) => {
    setOpen((prev) => !prev);
    setPayload((prev) => (prev ? null : payload));
  };

  return {
    toggle,
    Element: ({ message, title }) => (
      <Dialog open={isOpen} onClose={toggle}>
        <DialogTitle>{t(title ?? _title)}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t(message ?? _message)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle} color="error">
            {t('Cancel')}
          </Button>
          <LoadingButton onClick={onSubmit} autoFocus loading={loading}>
            {t('Agree')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    ),
  };
};
export default useConfirmationDialog;
