import { Field } from 'formik';
import { TextField } from '@mui/material';

const FormikInput = (props) => (
  <Field name={props.name}>
    {({ field, meta }) => (
      <TextField
        error={Boolean(meta?.touched && meta?.error)}
        helperText={meta?.touched && meta?.error}
        {...field}
        {...props}
      />
    )}
  </Field>
);

export default FormikInput;
