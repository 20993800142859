import API_CONFIG from 'routes/apiConfig';
import HttpClient from './HttpClient';

class CodeService {
  static getProblemList(params) {
    return HttpClient.get(API_CONFIG.PROBLEM_LIST, {
      params,
    });
  }

  static getProblemDetail(problemId) {
    return HttpClient.get(API_CONFIG.PROBLEM_DETAIL.replace(':problemId', problemId));
  }

  static getContestList(params) {
    return HttpClient.get(API_CONFIG.CONTEST_LIST, {
      params,
    });
  }

  static getContestDetail(contestId) {
    return HttpClient.get(API_CONFIG.CONTEST_DETAIL.replace(':contestId', contestId));
  }

  static getContestRanking(contestId, params) {
    return HttpClient.get(API_CONFIG.CONTEST_RANKING.replace(':contestId', contestId), {
      params,
    });
  }

  static getSubmissionList(params) {
    return HttpClient.get(API_CONFIG.SUBMISSION_LIST, {
      params,
    });
  }

  static createSubmission(payload) {
    return HttpClient.post(API_CONFIG.CREATE_SUBMISSION, payload);
  }

  static createTestSubmission(payload) {
    return HttpClient.post(API_CONFIG.CREATE_TEST_SUBMISSION, payload);
  }

  static getLeaderboard(contestId, params, real) {
    const API_ENDPOINT = real ? API_CONFIG.GET_REAL_LEADERBOARD : API_CONFIG.GET_LEADERBOARD;
    return HttpClient.get(API_ENDPOINT.replace(':contestId', contestId), {
      params,
    });
  }

  static createContest(payload) {
    return HttpClient.post(API_CONFIG.CREATE_CONTEST, payload);
  }

  static adminGetContestDetail(contestId) {
    return HttpClient.get(API_CONFIG.ADMIN_CONTEST_DETAIL.replace(':contestId', contestId));
  }

  static adminGetContestList(params) {
    return HttpClient.get(API_CONFIG.ADMIN_CONTEST_LIST, {
      params,
    });
  }

  static getContestJoined(params) {
    return HttpClient.get(API_CONFIG.GET_CONTEST_JOINED, {
      params,
    }).then((response) => {
      response.data = response?.data?.map((e) => e.contest);
      return response;
    });
  }

  static adminGetProblemList(params) {
    return HttpClient.get(API_CONFIG.ADMIN_PROBLEM_LIST, {
      params,
    });
  }

  static adminGetProblemDetail(problemId) {
    return HttpClient.get(API_CONFIG.ADMIN_PROBLEM_DETAIL.replace(':problemId', problemId));
  }

  static getListContestant(contestId) {
    return HttpClient.get(API_CONFIG.CONTESTANT_LIST.replace(':contestId', contestId));
  }

  static updateContest(contestId, payload) {
    return HttpClient.put(API_CONFIG.UPDATE_CONTEST.replace(':contestId', contestId), payload);
  }

  static removeContest(contestId) {
    return HttpClient.delete(API_CONFIG.REMOVE_CONTEST.replace(':contestId', contestId));
  }

  static createProblem(payload) {
    return HttpClient.post(API_CONFIG.CREATE_PROBLEM, payload);
  }

  static updateProblem(problemId, payload) {
    return HttpClient.put(API_CONFIG.UPDATE_PROBLEM.replace(':problemId', problemId), payload);
  }

  static removeProblem(problemId) {
    return HttpClient.delete(API_CONFIG.REMOVE_PROBLEM.replace(':problemId', problemId));
  }

  static createProblemFrom(problemId, contestId) {
    return HttpClient.post(
      API_CONFIG.DUPLICATE_PROBLEM.replace(':problemId', problemId).replace(':contestId', contestId),
    );
  }

  static joinContest(contestId, payload) {
    return HttpClient.post(API_CONFIG.JOIN_CONTEST.replace(':contestId', contestId), payload);
  }

  static getSubmissionDetail(submissionId) {
    return HttpClient.get(API_CONFIG.SUBMISSION_DETAIL.replace(':submissionId', submissionId));
  }

  static getAdminSubmissionDetail(submissionId) {
    return HttpClient.get(API_CONFIG.ADMIN_SUBMISSION_DETAIL.replace(':submissionId', submissionId));
  }
}

export default CodeService;
