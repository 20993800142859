import {
  Box,
  CircularProgress,
  Icon,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { isNil } from 'lodash';
import ReactInterval from 'react-interval';

import { CodeService } from 'services';
import { useEffect, useMemo, useRef } from 'react';
import { useAuth, useDebounce, usePagination, useQuery, useToast } from 'hooks';

import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { formatDate } from 'utils';
import Label from '@components/Label';
import { useTheme } from '@mui/material/styles';
import ROUTES from 'routes/paths';
import PropTypes from 'prop-types';
import { Person } from '@mui/icons-material';
import { SUBMISSION_STATUS } from 'utils/constants';
import contestLoopRestricted from 'pages/Dashboard/submission/constants/contest-loop-restricted';
import { SubmissionViewDialog } from './SubmissionViewDialog';

// ----------------------------------------------------------------------
StatusCell.propTypes = {
  status: PropTypes.string.isRequired,
};

function StatusCell({ status }) {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={1}>
      <Label
        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
        color={
          (['AC', 'PAC'].includes(status) && 'success') ||
          (['CE', 'TLE', 'RTE', 'IE'].includes(status) && 'error') ||
          (['WA'].includes(status) && 'warning') ||
          (['IQ', 'IP'].includes(status) && 'info') ||
          'primary'
        }
      >
        {SUBMISSION_STATUS[status]}
      </Label>
      {['IQ', 'IP'].includes(status) && <CircularProgress size={20} />}
    </Stack>
  );
}

const columns = [
  {
    field: 'id',
    headerName: '#',
    flex: 1,
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    flex: 2,
    renderCell: ({ value }) => formatDate(value, 'DDMMYYYYhhmmss'),
  },
  {
    field: 'problem',
    headerName: 'Problem',
    sortable: false,
    flex: 3,
    renderCell: ({ value }) => (
      <Link component={RouterLink} to={ROUTES.PROBLEM_DETAIL.replace(':problemId', value?.id)} underline="none">
        {value?.name}
      </Link>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: false,
    align: 'center',
    headerAlign: 'center',
    flex: 1.5,
    renderCell: ({ value }) => <StatusCell status={value} />,
  },
  {
    field: 'score',
    headerName: 'Score',
    flex: 1,
    type: 'number',
  },
  {
    field: 'time',
    headerName: 'Time (s)',
    flex: 1,
    type: 'number',
  },
  {
    field: 'memory',
    headerName: 'Memory (kB)',
    flex: 1,
    type: 'number',
  },
  {
    field: 'language',
    headerName: 'Language',
    sortable: false,
    flex: 1,
  },
  {
    field: 'createdBy',
    headerName: 'Created by',
    sortable: false,
    flex: 2,
    renderCell: ({ value }) => (
      <Link component={RouterLink} to={ROUTES.USER_PROFILE.replace(':username', value?.username)} underline="none">
        {value?.fullName ?? value?.username}
      </Link>
    ),
  },
  {
    field: 'violation',
    headerName: 'Violation',
    sortable: false,
    flex: 1,
    valueGetter: ({ row }) => {
      if (contestLoopRestricted.includes(row.problem.contestId)) {
        return row.violation;
      }
      if (row.violation === 'Loop violation') {
        return '';
      }
      return row.violation;
    },
  },
  {
    field: '_view',
    headerName: 'View',
    sortable: false,
    flex: 1,
    align: 'center',
    renderCell: ({ row }) =>
      row.viewable && (
        <IconButton onClick={row.view}>
          <Icon children="visibility" />
        </IconButton>
      ),
  },
];

export default function SubmissionList() {
  const { showError } = useToast();
  const { contestId = 0 } = useParams();
  const createdByQuery = useQuery('createdBy');
  const [{ user }] = useAuth();
  const location = useLocation();
  const { problemId } = location.state || { problemId: null };
  const submissionDialogRef = useRef();

  const [{ filter, data, total }, { getData, setFilterField, setFilter }] = usePagination({
    getDataFunc: CodeService.getSubmissionList,
    sortFieldMap: (fieldName) => `s.${fieldName}`,
  });

  useEffect(() => {
    if (!isNil(filter.contestId)) {
      getDataDebounced({ ...filter });
    }
  }, [filter]);

  useEffect(() => {
    setFilterField('contestId')(contestId);
    setFilterField('createdBy')(createdByQuery);
    setFilterField('problemId')(problemId);
  }, [contestId, createdByQuery]);

  const getDataDebounced = useDebounce(async (_filter = {}) => {
    try {
      await getData(_filter);
    } catch (e) {
      showError(e.message);
    }
  }, 500);

  useEffect(() => {
    setFilterField('sortModel')([{ field: 'createdAt', sort: 'desc' }]);
  }, []);

  const serializedData = useMemo(
    () =>
      data.map((row) => ({
        ...row,
        viewable: row.shared || row.createdById === user.id || user.role > 0,
        view: () => submissionDialogRef.current.view(row.id),
      })),
    [data, user],
  );

  return (
    <>
      <ReactInterval callback={() => getDataDebounced({ ...filter })} timeout={10000} enabled />
      <Toolbar
        query={filter.fullName}
        setQuery={setFilterField('fullName')}
        getData={() => getDataDebounced(filter)}
        setFilterField={setFilterField}
        user={user}
      />
      <DataGridPremium
        autoHeight
        disableSelectionOnClick
        sortingMode="server"
        paginationMode="server"
        density="comfortable"
        pagination
        disableColumnMenu
        columns={columns}
        sortModel={filter.sortModel}
        rows={serializedData}
        rowCount={total}
        paginationModel={filter}
        onPaginationModelChange={(model) => setFilter({ ...filter, ...model })}
        pageSizeOptions={[10, 20, 50]}
        columnVisibilityModel={{
          violation: user.role > 0,
        }}
      />
      <SubmissionViewDialog ref={submissionDialogRef} />
    </>
  );
}

const Toolbar = ({ query, setQuery, getData, setFilterField, user }) => {
  const handleSwitchChange = (e) => {
    if (e.target.checked) {
      setFilterField('userId')(user.id);
    } else {
      setFilterField('userId')(null);
    }
  };

  return (
    <Box sx={{ mt: 2 }} display="flex" alignItems="end" justifyContent="end" gap={1}>
      <Tooltip title="Refresh">
        <IconButton onClick={getData} sx={{ mr: 2 }}>
          <Icon children="autorenew" />
        </IconButton>
      </Tooltip>
      <TextField
        type="search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        size="small"
        placeholder="Search by name"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon children="search" />
            </InputAdornment>
          ),
        }}
      />
      <Stack flexDirection="row" alignItems="center">
        <Switch onChange={handleSwitchChange} />
        <Person color="primary" />
      </Stack>
    </Box>
  );
};
